import { template as template_53ea966791de4b3c917a5459db6aea0a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_53ea966791de4b3c917a5459db6aea0a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;

import { template as template_3a5fa82c05d742959d0ff3af17cd28aa } from "@ember/template-compiler";
const WelcomeHeader = template_3a5fa82c05d742959d0ff3af17cd28aa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_d80a2134aa7b44358a82c7026cf41192 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d80a2134aa7b44358a82c7026cf41192(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_a4bc6a93c3614b05bd120fccd6d85b86 } from "@ember/template-compiler";
const FKLabel = template_a4bc6a93c3614b05bd120fccd6d85b86(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
